import {useHistory, useRouteMatch} from "react-router-dom";
import {useQuery} from "react-query";
import {fetchChannels, installShopee, reinstallTokenBling} from "../../services";
import {useContext, useEffect, useState} from "react";
import { ClockIcon  } from "@heroicons/react/outline";

import {
    ActionMenu, ActionMenuItem,
    Button,
    Notification,
    FormattedDate,
    PageView,
    SearchInput,
    SlidePanel,
    TableHeader,
    DialogView,
    PageTopBar
} from "../../components";
import {AVAILABLE_INTEGRATIONS} from "../../navigation";
import {IMPORT_SHOPIFY_PRODUCTS, IMPORT_VTEX_PRODUCTS, INTEGRATIONS, SHIPPING_METHODS, STORE_PATH} from "../../navigation/constants";
import {ADD_BLING_INTEGRATION, ADD_COPPEL_INTEGRATION, ADD_LIVERPOOL_INTEGRATION, ADD_MELI_INTEGRATION, ADD_SHOPIFY_INTEGRATION, ADD_TIENDANUBE_INTEGRATION, ADD_VTEX_INTEGRATION, ADD_VENTIAPP_INTEGRATION, ADD_WALMART_INTEGRATION, ADD_WOOCOMMERCE_INTEGRATION, ADD_F1COMMERCE_INTEGRATION, ADD_TRAYCORP_INTEGRATION, ADD_VNDA_INTEGRATION, ADD_T1PAGINAS_INTEGRATION,ADD_TRAY_INTEGRATION, ADD_STRIPE_INTEGRATION, ADD_SHEIN_INTEGRATION, ADD_SHOPEE_INTEGRATION,ADD_TINY_INTEGRATION} from "../../navigation";
import { Loader } from "../../components/Loader";
import {ShippingMatchView} from "./ShippingMatchView"
import {LocationSelectView} from "./LocationSelectView"
import {syncIntegrationsShippingMethods,fetchSyncProduct,updateAutomaticSync} from "../../services";
import {UserContext} from "../../hooks/UserContext";
import { useTranslation } from "react-i18next";

import shopify from '../../assets/shopify.png';
import magento from '../../assets/magento.png';
import vtex from '../../assets/vtex.jpg';
import ventiapp from '../../assets/ventiapp.png';
import walmart from '../../assets/walmart.png';
import meli_logo from '../../assets/mercadolibre.jpg';
import privalia_logo from '../../assets/privalia.png';
import liverpool_logo from '../../assets/liverpool.jpeg';
import coppel_logo from '../../assets/coppel.webp';
import tiendanube from '../../assets/tiendanube.png';
import bling from '../../assets/bling.jpeg';
import api_logo from '../../assets/api.jpg';
import amazon_logo from '../../assets/amazon.png';
import f1commerce_logo from '../../assets/f1commerce.png';
import t1paginas_logo from '../../assets/t1paginas.png';
import shopee_logo from '../../assets/shopee.png';
import woocommerce from '../../assets/woocommerce.png';
import traycorp_logo from '../../assets/traycorp.svg';
import vnda_logo from '../../assets/vnda.jpg';
import stripe_logo from '../../assets/stripe.png';
import shein_logo from '../../assets/shein.png';
import tray_logo from '../../assets/traycommerce.png';
import tiny_logo from '../../assets/tiny.jpeg';
import vindi_logo from '../../assets/images/integration_logos/vindi_logo.svg'
import selia_logo from '../../assets/images/integration_logos/selia_logo.jpeg'

export const ListActiveIntegrations = () => {

    const { t, i18n} = useTranslation();
    const history = useHistory()
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })
    const [openDialogShippingMatch, setOpenDialogShippingMatch] = useState(false)
    const [openDialogLocationSelect, setOpenDialogLocationSelect] = useState(false)
    const {user} = useContext(UserContext)
    const [showNotification, setShowNotification] = useState(false)
    const [title, setTitle] = useState(false)
    const [selectChannel, setSelectChannel] = useState(false)
    const [showIntegrationLoading, setShowIntegrationLoading] = useState(false)
    const [queryParams, setQueryParams] = useState({
        page: 1,
        per_page: 20,
        store_id: user.current_store?.id,
    })
    const { data, status, isLoading, isError, isPreviousData, isFetching, refetch, error } = useQuery(['channels', queryParams], () => fetchChannels(queryParams))

    useEffect(() => {
        let newSearchParams = {...queryParams, store_id: user.current_store?.id}
        setQueryParams(newSearchParams)
    }, [user.current_store?.id])

    const STATUS = {
        "initial": i18n.t("integrations_list.active.configuring"), 
        "pending": i18n.t("integrations_list.active.configuring"),
        "active": i18n.t("integrations_list.active.active"),
        "not_connected": i18n.t("integrations_list.active.error"),
    }

    const columns = [
        {
            Header: "store_detail_container.name", 
            accessor: 'name', // accessor is the "key" in the data
        },
        {
            Header: "integrations_list.active.state", 
            accessor: 'state', // accessor is the "key" in the data
        },
        {
            Header: "claims.list.created_at", 
            accessor: 'created_at', // accessor is the "key" in the data
        },
        {
            Header: '',
            accessor: 'refresh', // accessor is the "key" in the data
        },
        {
            Header: '',
            accessor: 'actions', // accessor is the "key" in the data
            fetchingIndicator : true,
            shrinkToContent: true
        },
    ]
    const getIntegrationLogo = (channel) => {

        switch (channel.channel_type_name)
        {
        case "shopify":
        case "shopify_public":
            return shopify
        case "woocommerce":
            return woocommerce
        case "vtex":
            return vtex
        case "privalia":
            return privalia_logo
        case "openapi":
            return api_logo
        case "ventiapp":
            return ventiapp
        case "walmart":
            return walmart
        case "tiendanube":
            return tiendanube
        case "meli":
            return meli_logo
        case "bling":
            return bling
        case "bling_v3":
            return bling
        case "amazon":
            return amazon_logo
        case "liverpool":
            return liverpool_logo
        case "coppel":
            return coppel_logo
        case "f1commerce":
            return f1commerce_logo
        case "traycorp":
            return traycorp_logo
        case "traycommerce":
            return tray_logo
        case "vnda":
            return vnda_logo
        case "t1paginas":
            return t1paginas_logo
        case "vindi":
            return vindi_logo
        case "stripe":
            return stripe_logo
        case "shopee":
            return shopee_logo     
        case "shein":
            return shein_logo
        case "selia":
            return selia_logo
        case "tiny":
            return tiny_logo
        default: 
            return shopify
        }

    }

    const getIntegrationName = (channel) => {

        switch (channel.channel_type_name)
        {
        case "shopify":
        case "shopify_public":
            return "Shopify"
        case "woocommerce":
            return "WooCommerce"
        case "vtex":
            return "Vtex"
        case "liverpool":
            return "Liverpool" 
        case "liverpool":
            return "Privalia"   
        case "meli":
            return "Mercado Libre"
        case "openapi":
            return "API"
        case "ventiapp":
            return "VentiApp"
        case "walmart":
            return "Walmart"
        case "tiendanube":
            return "Tiendanube"
        case "bling":
            return "Bling"
        case "bling_v3":
            return "Bling v3"
        case "coppel":
            return "Coppel"
        case "amazon":
            return "Amazon"
        case "f1commerce":
            return "F1commerce"
        case "traycorp":
            return "Tray Corp"
        case "traycommerce":
            return "Tray."
        case "vnda":
            return "Vnda."
        case "t1paginas":
            return "T1Páginas"
        case "vindi":
            return "Vindi"
        case "selia":
            return "Selia"
        case "tiny":
            return "Tiny"
        default: 
            return ""
        }

    }

    const getStatusPill = (channel) => {
        if (channel.status === "active") {
            return <div className="italic text-green-500">{i18n.t('integrations_list.active.active')}</div>
        } else if (channel.status === "initial" || channel.status === "pending"){
            return <div className="italic text-gray-500">Configurando...</div>
        } else if (channel.status === "not_connected") {
            return <div className="italic text-red-500">{i18n.t('integrations_list.active.error')}</div>
        } else {
            return <div className="italic text-red-500">{i18n.t("integrations_list.active.deleted")}</div>
        }
    }

    const goToImportProducts = (channel) => {

        let path = ''
        if (channel.channel_type_name === "vtex") {
            path = IMPORT_VTEX_PRODUCTS
        } else {
            path = IMPORT_SHOPIFY_PRODUCTS
        }   

        history.push(storePath + path.replace(":id", channel.id))
    }

    const onClickAddIntegration = () => {
        history.push(storePath + AVAILABLE_INTEGRATIONS)
    }

    const onClickSelectIntegration = (integration) => {
        history.push(storePath + integration)
    }
    
    const handleGetAdmin = () => {
        let index = user.roles.findIndex(roll => {return  roll=="ROLE_SUPER_ADMIN"})
        return  index != -1
    }
    const getMenuOptions = (channel) => {
        const options = []

        if (channel.channel_type_name === 'meli' || channel.channel_type_name === 'liverpool')
            return options

        options.push({
            title: i18n.t("integrations_list.active.shipping_methods"),
            clickHandler: () => history.push(storePath + SHIPPING_METHODS.replace(":id", channel.id)),
        })

        switch (channel.channel_type_name) {
            case "vtex":
                options.push(
                    {
                        title: i18n.t("integrations_list.active.assign_a_location"),  
                        clickHandler: () => {
                            setOpenDialogLocationSelect(true)
                            setSelectChannel(channel)
                        }
                    },
                    {
                        title: i18n.t("integrations_list.active.import_vtex_products"),  
                        clickHandler: () => history.push(storePath + IMPORT_VTEX_PRODUCTS.replace(":id", channel.id)),
                        
                    },
                )
                break;
            case "shopify_public":
            case "shopify":
                options.push(
                    {
                        title: i18n.t("integrations_list.active.assign_a_location"),   
                        clickHandler: () => {
                            setOpenDialogLocationSelect(true)
                            setSelectChannel(channel)
                        }
                    },
                    {
                        title: i18n.t("integrations_list.active.import_vtex_products"),
                        clickHandler: () => history.push(storePath + IMPORT_SHOPIFY_PRODUCTS.replace(":id", channel.id)),
                        
                    },
                )
                break;
    
            default:
                break;
        }
        
        if(handleGetAdmin()){
           options.push( {
            title: i18n.t("integrations_list.active.products_sync"), 
            clickHandler: () => {handleClickOnSyncProducts(channel.id)}
           
            })
        }
        return options
    }

    const handleSaveShippingMatch = (data) => {
        setOpenDialogShippingMatch(false)
    }
    const handleSaveLocationSelect = (data) => {
        setOpenDialogLocationSelect(false)
    }
    const handleClickOnSync = async (id) => {
        try {
            let response = await syncIntegrationsShippingMethods(id)
            setTimeout(() => {
                setTitle: i18n.t("integrations_list.active.updated_shipping_methods") 
                setShowNotification(true)
            }, 500)
        } catch (error) {
            console.log(error)
        }
    }

    const setInventorySync = async (channel) => {
        setShowIntegrationLoading(true)
        const response = await updateAutomaticSync(channel.id, !channel.is_syncing_inventory)
        refetch()
        setShowIntegrationLoading(false)
        if (!channel.is_syncing_inventory){
            setTitle(i18n.t("integrations_list.active.sync_activated"))
        } else {
            setTitle(i18n.t("integrations_list.active.sync_paused"))
        }
        setShowNotification(true)
    }
    const handleClickOnSyncProducts = async (id) => {
        
        const response = await fetchSyncProduct(id)
        setTimeout(() => {
            setTitle (i18n.t("integrations_list.active.updated_inventory"))
            setShowNotification(true)
        }, 500);
    }

    const handleBlingReinstallToken = async (id) => {
        const response = await reinstallTokenBling(id)
        if (response.status===201 || response.status===200) {
            if(response.data.link) window.open(response.data.link)
            else history.push(storePath + INTEGRATIONS)
        }
    }

    const getTableData = () => {
        return data.channel_integrations.map(channel=>{
            return {
                ...channel,
                name: channel.config?.domain || channel.config?.app_name,
                created_at: (
                    <FormattedDate date={channel.created_at} shortDate/>
                ),
                state: (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {STATUS[channel.status]}
                    </span>
                ),
                refresh: (

                <div>
                    <div className='has-tooltip'>
                        {channel.channel_type_name !== 'openapi' && channel.channel_type_name !== 'meli' && channel.channel_type_name !== 'liverpool' &&
                        <>
                            <span className='tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-8'>{i18n.t("integrations_list.active.Sync_shipping_methods")}</span>
                            <a className="cursor-pointer ml-3 relative inline-flex items-center px-2 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" 
                                onClick={() => {handleClickOnSync(channel.id)}}>
                                <ClockIcon className="h-4 h-4"/>
                            </a>
                        </>
                        }
                    </div>
                </div>
                ),
                actions: (
                        <ActionMenu className="float-right" items={getMenuOptions(channel)}/>
                 
                )
            }
        })
    }

    const authorize_shopee_product = async (event) => {

        try {
            let res = await installShopee(user.current_store?.id, "products")

            if(res.status===422 && res.data?.error?.startsWith("ChannelIntegration Already exist"))
                throw i18n.t("integration.shopee.Existing_sales_channel")
        
            if(res.status===201 || res.status===200){
                if(res.data.link)
                    window.open(res.data.link)
                else
                    history.push(storePath + AVAILABLE_INTEGRATIONS)
                //window.location.href = res.data.link
            }
        } catch (error) {
            console.error("Invalid data, Err==", error)
            alert (i18n.t("integration.shopee.Error_creating_integration"))
    
        }
    }

    const authorize_shopee_finance = async (event) => {

        try {
            let res = await installShopee(user.current_store?.id, "finance")

            if(res.status===422 && res.data?.error?.startsWith("ChannelIntegration Already exist"))
                throw i18n.t("integration.shopee.Existing_sales_channel")
        
            if(res.status===201 || res.status===200){
                if(res.data.link)
                    window.open(res.data.link)
                else
                    history.push(storePath + AVAILABLE_INTEGRATIONS)
                //window.location.href = res.data.link
            }
        } catch (error) {
            console.error("Invalid data, Err==", error)
            alert (i18n.t("integration.shopee.Error_creating_integration"))
    
        }
    }

    const products = [
        {
            name: "Shopify",
            img: shopify,
            link: ADD_SHOPIFY_INTEGRATION
        },
        {
            name: "Vtex",
            img: vtex,
            link: ADD_VTEX_INTEGRATION
        },
        {
            name: "privalia",
            img: privalia_logo,
            link: "mailto:integraciones@cubbo.com"
        },
        {   
            name: "Mercado Libre",
            img: meli_logo,
            link: ADD_MELI_INTEGRATION
        },
        {
            name: "Bling",
            img: bling,
            link: ADD_BLING_INTEGRATION
        },
        {
            name: "Tienda Nube",
            img: tiendanube,
            link: ADD_TIENDANUBE_INTEGRATION
        },
        {
            name: "Woocommerce",
            img: woocommerce,
            link: ADD_WOOCOMMERCE_INTEGRATION
        },
        {
            name: "Walmart",
            img: walmart,
            link: ADD_WALMART_INTEGRATION
        },
        {
            name: "Coppel",
            img: coppel_logo,
            link: ADD_COPPEL_INTEGRATION
        },
        // {
        //     name: "Liverpool",
        //     img: liverpool_logo,
        //     link: ADD_LIVERPOOL_INTEGRATION
        // },
        {
            name: "Ventiapp",
            img: ventiapp,
            link: ADD_VENTIAPP_INTEGRATION
        },
        {
            name: "Tray Corp",
            img: traycorp_logo,
            link: ADD_TRAYCORP_INTEGRATION
        },
        {
            name: "Tray.",
            img: tray_logo,
            link: ADD_TRAY_INTEGRATION
        },
        {
            name: "Tiny",
            img: tiny_logo,
            link: ADD_TINY_INTEGRATION
        },
        {
            name: "Vnda.",
            img: vnda_logo,
            link: ADD_VNDA_INTEGRATION
        },
        {
            name: "Stripe.",
            img: stripe_logo,
            link: ADD_STRIPE_INTEGRATION
        },
        {
            name: "Shein.",
            img: shein_logo,
            link: ADD_SHEIN_INTEGRATION
        },
        {
            name: "F1commerce",
            img: f1commerce_logo,
            link: ADD_F1COMMERCE_INTEGRATION
        },
        {
            name: "T1Páginas",
            img: t1paginas_logo,
            link: ADD_T1PAGINAS_INTEGRATION
        },
        {
            name: "Shopee",
            img: shopee_logo,
            link: ADD_SHOPEE_INTEGRATION
        }
    ];

    return (
        <>
        <Notification show={showNotification} setShow={setShowNotification} title={title}/>
        <Notification
                show={showIntegrationLoading}
                setShow={setShowIntegrationLoading}
                customBody={
                <>
                <div className="flex-shrink-0">
                    <ClockIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                
                <div className="ml-5 text-sm font-medium text-gray-900">Configurando...</div>
                   
            </>}
                ephemeral={false}
        />
        <DialogView setOpen={setOpenDialogShippingMatch} open={openDialogShippingMatch}>
            <ShippingMatchView
                title= {i18n.t("integrations_list.active.shipping_methods")}
                id={selectChannel}
                onSave={handleSaveShippingMatch}
                onCancel={() => setOpenDialogShippingMatch(false)}
            />
        </DialogView>
        <DialogView setOpen={setOpenDialogLocationSelect} open={openDialogLocationSelect}>
            <LocationSelectView
                title= {i18n.t("integrations_list.active.assign_location")} 
                channel={selectChannel}
                onSave={handleSaveLocationSelect}
                onCancel={() => setOpenDialogLocationSelect(false)}
            />
        </DialogView>
        <PageView
            topMenu={<PageTopBar>
                <div className="text-lg font-semibold"><span className="text-gray-400">{ user.current_store ? user.current_store.name : i18n.t("integrations_list.active.all_stores") } &rarr; </span> {i18n.t("integrations_list.active.integration")}</div>
                { user.current_store &&
                    <Button onClick={onClickAddIntegration}> {i18n.t("integrations_list.active.create_integration")}</Button>
                }
            </PageTopBar>}
            //bottomMenu={<Button onClick={onClickAddIntegration}>Crear Integración</Button>}
        >
            
            {!isPreviousData && isLoading ? (
                <><Loader show={true}></Loader></>
            ) : isError ? (
                <>Error: {error.message}</>
            ) : (
                <>
                {data.channel_integrations.length > 0 ? 
                
                <>
                    <ul role="list" className="grid grid-cols-1 gap-14 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 mt-10">
                        {data.channel_integrations.map((channel) => (
                        
                            <li
                            key={channel.id}
                            className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white shadow"
                            >
                                {/* {console.log(channel)} */}

                            <div className="bg-gray-50 flex flex-wrap sm:flex-nowrap">
                                <div className="p-4">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-12 w-12"
                                                src={getIntegrationLogo(channel)}
                                                alt=""
                                            />
                                        </div>
                                        <div className="ml-4">
                                        
                                            <h3 className="text-lg font-medium leading-5 text-gray-900">{getIntegrationName(channel)}</h3>
                                            <p className="text-sm text-gray-500">
                                                {channel.config?.domain || channel.config?.app_name || channel.config?.shop_id}
                                            </p>
                                            {getStatusPill(channel)}
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="ml-4 mt-4 flex flex-shrink-0">
                                </div>
                            </div>
                            <   div className="text-base">
                                {
                                    ['shopify_public', 'shopify', 'vtex', 'traycorp', 't1paginas', 'vnda', 'shein'].includes(channel.channel_type_name) || (channel.channel_type_name == 'shopee' && channel.config?.access_token_products) ?
                                    <>
                                        <div className="border-t border-gray-200">
                                            <div className="py-4 px-4">
                                                <div className="flex justify-between">
                                                    <div className="truncate font-medium">{i18n.t("integrations_list.active.location")}</div>
                                                    <div className="ml-2 flex flex-shrink-0">                                             
                                                    <a className="cursor-pointer" onClick={() => {
                                                            setOpenDialogLocationSelect(true)
                                                            setSelectChannel(channel)
                                                        }}>{i18n.t("integrations_list.active.assign_a_location")}</a>
                                                    </div>
                                                </div>
                                                <div className="mt-2 sm:flex sm:justify-between">
                                                    <div className="sm:flex">
                                                        <p className="flex text-sm text-gray-500">

                                                        {
                                                            channel.location ? <>ID: {channel.location}</> : <>Sin Asignar</>
                                                        }
                                                        </p>
                                        
                                                    </div>
                                                    <div className="mt-2 flex text-sm text-gray-500 sm:mt-0">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                                }
                                
                                {!['meli','coppel','walmart', 'privalia', 'shein', 'shopee'].includes(channel.channel_type_name)  ? 
                                
                                <>
                                    <div className="border-t border-gray-200">

                                        <div className="px-4 py-4 px-10">
                                            <div className="flex justify-between">
                                            <div className="truncate font-medium">{i18n.t('integrations_list.active.shipping_methods')}</div>
                                            <div className="ml-2 flex flex-shrink-0">
                                                {/* <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                Sin Asignar
                                                </p> */}
                                                <a className="cursor-pointer" onClick={() => {history.push(storePath + SHIPPING_METHODS.replace(":id", channel.id))}}>
                                                    {i18n.t('integrations_list.active.configure')}
                                                </a>
                                            </div>
                                            </div>
                                            <div className="mt-2 sm:flex sm:justify-between">
                                            <div className="sm:flex">
                                                <p className="flex text-sm text-gray-500">
                                                {/* <UsersIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                                                {/* Sin Asignar */}
                                                </p>
                                                {/* <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                                </p> */}
                                            </div>
                                            <div className="mt-2 flex text-sm text-gray-500 sm:mt-0">
                                                {/* <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                                                {/* <p>
                                                Closing on <time dateTime="">{}</time>
                                                </p> */}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </> 
                                : 
                                <>
                                
                                </>}
                                
                                        
                                        
                                { ["shopee"].includes(channel.channel_type_name) && !channel.config?.access_token_finance  &&
                                    <div className="border-t border-gray-200">
                                        <div className="px-4 py-4 px-10">
                                            <div className="flex justify-between">
                                                <div className="truncate font-medium">{i18n.t("integrations_list.active.finance")}</div>
                                                <div className="ml-2 flex flex-shrink-0">
                                                    <div className="text-right">
                                                        <a className="leading-7 cursor-pointer" onClick={() => authorize_shopee_finance()}>{i18n.t("integrations_list.active.authorize_app_shopee_finance")}</a><br></br>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                       

                                <div className="border-t border-gray-200">

                                    <div className="px-4 py-4 px-10">
                                        
                                        
                                        {
                                            !["openapi", "bling", "bling_v3", "vindi"].includes(channel.channel_type_name) ? 
                                            <>
                                                <div className="flex justify-between">
                                                <div className="truncate font-medium">{i18n.t("topMenu.inventory")}</div>
                                                <div className="ml-2 flex flex-shrink-0">
                                                    <div className="text-right">
                                                        { channel.channel_type_name == 'shopee' && !channel.config?.access_token_products ?
                                                        <>
                                                            <a className="leading-7 cursor-pointer" onClick={() => authorize_shopee_product()}>{i18n.t("integrations_list.active.authorize_app_shopee_products")}</a><br></br>
                                                        </>: <></>
                                                        }

                                                        <a className="leading-7 cursor-pointer" onClick={() => handleClickOnSyncProducts(channel.id)}>{i18n.t("integrations_list.active.products_sync")}</a><br></br>
                                                        {['shopify_public', 'shopify', 'vtex'].includes(channel.channel_type_name) ? <><a className="leading-7" onClick={() => goToImportProducts(channel)}>{i18n.t("integrations_list.active.import_vtex_products")}</a><br></br></> : <></>}
                                                        
                                                        <a className="leading-7 cursor-pointer"
                                                        onClick={() => setInventorySync(channel)}>
                                                            {channel.is_syncing_inventory ? i18n.t("integrations_list.active.pause_sync") :  i18n.t("integrations_list.active.activate_sync")}
                                                            
                                                        </a>
                                                    </div>
                                                </div>
                                                </div>
                                            </> 
                                            : 
                                            <></>
                                        }
                                       
                                       {channel.channel_type_name === "bling_v3" &&
                                        <div className="mt-2 sm:flex sm:justify-between">
                                            <div className="truncate font-medium">{i18n.t("integrations_list.active.token")}</div>
                                                <div className="ml-2 flex flex-shrink-0">
                                                <div className="text-right">
                                                    <a className="leading-7 cursor-pointer" onClick={() => handleBlingReinstallToken(channel.id)}>
                                                        {i18n.t("integrations_list.active.generate_new_token")}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            </li>
                        ))}
                    </ul>
                </> 
                
                : 
                
                <>
                
                    <div className="flex flex-col lg:flex-row gap-10">
                        <div className="flex flex-col gap-3 flex-wrap justify-center w-full mt-10 lg:mt-0 lg:w-1/2">
                            <div className="text-base md:text-xl text-gray-400">{i18n.t("integrations_list.empty_list.first_integration")}</div>
                            <div className="text-xl md:text-3xl font-bold">
                            {i18n.t("integrations_list.empty_list.select_preferred_integration")}
                            </div>
                        </div>
                        <div className="justify-items-center grid grid-flow-row grid-cols-3 md:grid-cols-5 lg:py-20 w-full lg:w-1/2">
                            {products.map((item) => (
                                <div key={item.name} onClick={() => onClickSelectIntegration(item.link)} 
                                    className="cursor-pointer w-full m-2 py-1 rounded-md transition transform hover:scale-105
                                                hover:shadow-md border border-transparent hover:border-gray-300">
                                        <div className="flex flex-col mt-2 items-center">
                                            <img src={item.img} className="rounded-md h-16 w-16 lg:h-20 lg:w-20"/>
                                            <div className="mt-1 mb-0 text-center">{item.name}</div> 
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
                
                }
                
                </>
            )}
        </PageView>
        </>
    )
}