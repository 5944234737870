import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment-timezone"

const OrderEta = ({orderEta, timeZone}) => {
    const { i18n } = useTranslation();
    const [formattedEta, setFormattedEta] = useState(null)

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    useEffect(() => {
        if (orderEta){
            const etaBegin = moment(orderEta.range_begin).tz(timeZone).locale(i18n.language)
            const etaEnd = moment(orderEta.range_end).tz(timeZone).locale(i18n.language)

            let newFormattedEta = null

            if(etaBegin.format('L') !== etaEnd.format('L')){
                const etaBeginDay = etaBegin.date()
                const etaBeginMonth = capitalizeFirstLetter(etaBegin.format('MMMM'))

                const etaEndDay = etaEnd.date()
                const etaEndMonth = capitalizeFirstLetter(etaEnd.format('MMMM'))

                if(etaBeginMonth === etaEndMonth){
                    newFormattedEta = `${etaBeginDay} - ${etaEndDay} ${etaEndMonth}`
                } else{
                    newFormattedEta = `${etaBeginDay} ${etaBeginMonth} - ${etaEndDay} ${etaEndMonth}`
                }

                newFormattedEta = i18n.t('orders.eta_range', {eta_range: newFormattedEta})
            } else{
                const daysBetweenTodayAndEta = etaEnd.clone().endOf('day').diff(moment().tz(timeZone).endOf('day'), 'days', false)

                const etaEndDay = etaEnd.date()
                const etaEndMonth = capitalizeFirstLetter(etaEnd.format('MMMM'))

                if (daysBetweenTodayAndEta < -1){
                    newFormattedEta = i18n.t('formatted_relative_time.from_now', {days: Math.abs(daysBetweenTodayAndEta)})
                } else if (daysBetweenTodayAndEta === -1){
                    newFormattedEta = i18n.t('formatted_relative_time.yesterday')
                } else if (daysBetweenTodayAndEta === 0){
                    newFormattedEta = i18n.t('formatted_relative_time.today')
                } else if (daysBetweenTodayAndEta === 1){
                    newFormattedEta = i18n.t('formatted_relative_time.tomorrow')
                } else {
                    newFormattedEta = etaEnd.clone().endOf('day').from(moment().tz(timeZone).endOf('day'))
                    newFormattedEta = capitalizeFirstLetter(newFormattedEta)
                }

                newFormattedEta = `${newFormattedEta}, ${etaEndDay} ${etaEndMonth}`
            }

            setFormattedEta(newFormattedEta)
        }
    }, [orderEta, timeZone, i18n])

    return <span>{formattedEta}</span>
}

export default OrderEta
