import { Button, PageView, PageTopBar, ButtonLoader, Notification } from "../../components"
import { useParams, useHistory, useRouteMatch } from "react-router-dom"
import { useQuery } from "react-query"
import { useContext, useState, useEffect, useMemo } from "react"
import { Loader } from "../../components/Loader"
import { Table, Select, Empty } from "antd"
import { UserContext } from "../../hooks/UserContext"
import { cloneDeep, sortBy } from "lodash"
import { useTranslation } from "react-i18next"
import { fetchChannelIntegrationDetail, syncIntegrationsShippingMethods, updateShippingType } from "../../services/shippingMethodsServices"
import { INTEGRATIONS, STORE_PATH } from "../../navigation/constants"
import { ShippingGroupCard } from "../../components/ShippingGroups/ShippingGroupCard"
import { AssignShippingGroupModal } from "./ShippingGroups/AssignShippingGroupModal"
import { CreateIntegrationShippingMethodModal } from "./ShippingGroups/CreateIntegrationShippingMethodModal"
import { shippingGroupHasIncompatibleShippingMethods } from "../../components/ShippingGroups/ShippingMethodTable"
import { ExclamationIcon } from "@heroicons/react/solid"
import { capitalizeFirstLetter } from "../../utils/StringUtils"

export const AssignShippingGroups = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const { user } = useContext(UserContext)
    const history = useHistory()
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })
    const [allowNewIntegrationShippingMethods, setAllowNewIntegrationShippingMethods] = useState(false)
    const [channelType, setChannelType] = useState({})
    const [integrationShippingMethods, setIntegrationShippingMethods] = useState([])
    const [channelIntegration, setChannelIntegration] = useState({})
    const [showNotification, setShowNotification] = useState(false)
    const [notificationContent, setNotificationContent] = useState({ title: null, description: null, type: null })
    const [loadingSyncIntegrationShippingMethods, setLoadingSyncIntegrationShippingMethods] = useState(false)
    const [selectedIntegrationShippingMethod, setSelectedIntegrationShippingMethod] = useState(null)
    const [showAssignShippingGroupModal, setShowAssignShippingGroupModal] = useState(false)
    const [showCreateIntegrationShippingMethodModal, setShowCreateIntegrationShippingMethodModal] = useState(false)

    const {
        isLoading: isLoadingChannelIntegration,
        isError: isErrorChannelIntegration,
        error: errorChannelIntegration,
        data: dataChannelIntegration,
        isFetching: isFetchingChannelIntegration,
        isPreviousData: isPreviousDataChannelIntegration,
        refetch: refetchChannelIntegration,
    } = useQuery(["channel_integration_detail", id], () => fetchChannelIntegrationDetail(id), { keepPreviousData: true, refetchOnWindowFocus: false })

    useEffect(() => {
        if (!dataChannelIntegration) return
        updateLocalStates(dataChannelIntegration)
    }, [dataChannelIntegration])

    const updateLocalStates = (newChannelIntegrationData) => {
        let channelType = newChannelIntegrationData.channel_type
        setChannelType(channelType)
        let integrationShippingMethods = newChannelIntegrationData.integration_shipping_methods.filter(ism => ism.status === 'ACTIVE')
        integrationShippingMethods = integrationShippingMethods.map((ism) => {
            return { ...ism, loadingShippingType: false }
        })
        integrationShippingMethods = sortBy(integrationShippingMethods, [(ism) => (ism.shipping_group ? 1 : 0), (ism) => ism.name])
        setIntegrationShippingMethods(integrationShippingMethods)
        setChannelIntegration(newChannelIntegrationData)
        if (['openapi', 'ventiapp', 'f1commerce','liverpool','vnda', 'traycommerce', 'stripe','tiny'].includes(channelType.name) ) {
            setAllowNewIntegrationShippingMethods(true)
        } else {
            setAllowNewIntegrationShippingMethods(false)
        }
    }

    const ColumnHeader = ({ title, tip }) => (
        <div>
            <div>{title}</div>
            <div className="text-xs text-gray-400 max-w-20">{tip}</div>
        </div>
    )

    const integrationShippingMethodsColumns = [
        {
            title: (
                <ColumnHeader
                    title={`${t("integrations_list.shipping.integration_shipping_method_prefix")} ${capitalizeFirstLetter(channelType?.name)}`}
                    tip={t("integrations_list.shipping.column_help.integration_shipping_method")}
                />
            ),
            dataIndex: "name",
            key: "name",
            className: "align-top",
        },
        {
            title: (
                <ColumnHeader title={t("integrations_list.shipping.Type_shipping_method")} tip={t("integrations_list.shipping.column_help.shipping_type")} />
            ),
            dataIndex: "shipping_type",
            key: "shipping_type",
            className: "align-top",
        },
        {
            title: <ColumnHeader title={t("integrations_list.shipping.shipping_group")} tip={t("integrations_list.shipping.column_help.shipping_group")} />,
            dataIndex: "shipping_group",
            key: "shipping_group",
            className: "align-top",
        },
    ]

    const syncIntegrationShippingMethods = async () => {
        if (loadingSyncIntegrationShippingMethods) return
        if (!channelIntegration?.id) return
        setLoadingSyncIntegrationShippingMethods(true)
        try {
            await syncIntegrationsShippingMethods(channelIntegration.id)
            refetchChannelIntegration()
            setNotificationContent({
                title: t("integrations_list.shipping.success_sync_integration_shipping_methods"),
                type: "success",
            })
        } catch (error) {
            console.log(error)
            setNotificationContent({
                title: t("integrations_list.shipping.error_sync_integration_shipping_methods"),
                type: "error",
            })
        }
        setLoadingSyncIntegrationShippingMethods(false)
        setShowNotification(true)
    }

    const changeShippingType = async (indexIntegrationShippingMethod, newShippingType) => {
        let previousShippingType = integrationShippingMethods[indexIntegrationShippingMethod].shipping_type
        let newIntegrationShippingMethods = cloneDeep(integrationShippingMethods)
        newIntegrationShippingMethods[indexIntegrationShippingMethod].shipping_type = newShippingType
        newIntegrationShippingMethods[indexIntegrationShippingMethod].loadingShippingType = true
        setIntegrationShippingMethods(newIntegrationShippingMethods)
        try {
            await updateShippingType(integrationShippingMethods[indexIntegrationShippingMethod].id, newShippingType)
            // refetchChannelIntegration()
            setNotificationContent({
                title: t("integrations_list.shipping.success_update_shipping_type"),
                type: "success",
            })
        } catch (error) {
            newIntegrationShippingMethods[indexIntegrationShippingMethod].shipping_type = previousShippingType
            setNotificationContent({
                title: t("integrations_list.shipping.error_update_shipping_type"),
                type: "error",
            })
        }
        newIntegrationShippingMethods[indexIntegrationShippingMethod].loadingShippingType = false
        newIntegrationShippingMethods = cloneDeep(newIntegrationShippingMethods)
        setIntegrationShippingMethods(newIntegrationShippingMethods)
        setShowNotification(true)
    }

    const tableData = useMemo(() => {
        if (!integrationShippingMethods) return
        let tableData = integrationShippingMethods.map((integrationShippingMethod, index) => {
            let shippingType = (
                <div>
                    <div>
                        <Select
                            value={integrationShippingMethod.shipping_type}
                            onChange={(val) => changeShippingType(index, val)}
                            size={"large"}
                            className="min-w-12"
                            loading={integrationShippingMethod.loadingShippingType}
                            disabled={integrationShippingMethod.loadingShippingType}
                        >
                            <Select.Option value="national">{t("integrations_list.shipping.integration_shipping_types.national.label")}</Select.Option>
                            <Select.Option value="international">
                                {t("integrations_list.shipping.integration_shipping_types.international.label")}
                            </Select.Option>
                            <Select.Option value="cash_on_delivery">
                                {t("integrations_list.shipping.integration_shipping_types.cash_on_delivery.label")}
                            </Select.Option>
                        </Select>
                    </div>
                    <div className="text-sm text-gray-400 mt-2">
                        {t(`integrations_list.shipping.integration_shipping_types.${integrationShippingMethod.shipping_type}.tip`)}
                    </div>
                    {integrationShippingMethod.shipping_group &&
                        shippingGroupHasIncompatibleShippingMethods(
                            integrationShippingMethod.shipping_group.shipping_method_groups,
                            integrationShippingMethod.shipping_type
                        ) && (
                            <div className="max-w-20 mt-4 flex text-gray-400">
                                <div>
                                    <ExclamationIcon className="w-5 h-5 mr-1" />
                                </div>
                                <div className="text-xs">{t("shipping_groups.incompatible_shipping_type")}</div>
                            </div>
                        )}
                </div>
            )
            let name = <span className="text-lg font-semibold">{integrationShippingMethod.name}</span>

            let shippingGroup = (
                <>
                    {integrationShippingMethod.shipping_group ? (
                        <>
                            <ShippingGroupCard
                                horizontalLayout={true}
                                shippingGroupData={integrationShippingMethod.shipping_group}
                                integrationShippingMethodType={integrationShippingMethod.shipping_type}
                                onClick={() => {
                                    setSelectedIntegrationShippingMethod(integrationShippingMethod)
                                    setShowAssignShippingGroupModal(true)
                                }}
                            />
                        </>
                    ) : (
                        <div>
                            <div className="max-w-30 flex text-red-500">
                                <div>
                                    <ExclamationIcon className="w-5 h-5 mr-1" />
                                </div>
                                <div className="italic text-sm mb-2 max-w-30">{t("integrations_list.shipping.missing_shipping_group_tip")}</div>
                            </div>
                            <ButtonLoader
                                onClick={() => {
                                    setSelectedIntegrationShippingMethod(integrationShippingMethod)
                                    setShowAssignShippingGroupModal(true)
                                }}
                                type="secondary"
                            >
                                {t("integrations_list.shipping.assign_shipping_group")}
                            </ButtonLoader>
                        </div>
                    )}
                </>
            )

            return {
                ...integrationShippingMethod,
                name: name,
                shipping_type: shippingType,
                shipping_group: shippingGroup,
            }
        })
        return tableData
    }, [integrationShippingMethods, t])

    return (
        <>
            <Notification show={showNotification} setShow={setShowNotification} title={notificationContent.title} type={notificationContent.type} />
            <AssignShippingGroupModal
                show={showAssignShippingGroupModal}
                integrationShippingMethod={selectedIntegrationShippingMethod}
                close={() => setShowAssignShippingGroupModal(false)}
                onSave={(savedShippingGroupId) => {
                    refetchChannelIntegration()
                    setSelectedIntegrationShippingMethod({ ...selectedIntegrationShippingMethod, shipping_group: { id: savedShippingGroupId } })
                }}
            />
            <CreateIntegrationShippingMethodModal
                channelIntegrationName={channelIntegration?.name}
                channelIntegrationId={channelIntegration.id}
                show={showCreateIntegrationShippingMethodModal}
                close={() => setShowCreateIntegrationShippingMethodModal(false)}
                onConfirm={() => {
                    refetchChannelIntegration()
                    setShowCreateIntegrationShippingMethodModal(false)
                }}
            />
            <PageView
                topMenu={
                    <PageTopBar>
                        <div className="text-lg font-semibold">
                            <span className="text-gray-400">
                                {user?.current_store?.name} &rarr;{" "}
                                <span className="hover:underline cursor-pointer" onClick={() => history.push(storePath + INTEGRATIONS)}>
                                    {t("integrations_list.active.integration")}
                                </span>{" "}
                            </span>{" "}
                            &rarr; {channelIntegration?.safe_config?.domain}
                        </div>
                        <div className="pr-3">
                            {allowNewIntegrationShippingMethods ? (
                                <ButtonLoader
                                    className="whitespace-nowrap mr-3"
                                    type={"secondary"}
                                    onClick={() => setShowCreateIntegrationShippingMethodModal(true)}
                                >
                                    {t("integrations_list.shipping.Add_Shipping_Method")}
                                </ButtonLoader>
                            ) : (
                                <ButtonLoader
                                    className="whitespace-nowrap mr-3"
                                    type={"secondary"}
                                    onClick={() => syncIntegrationShippingMethods()}
                                    loading={loadingSyncIntegrationShippingMethods || isLoadingChannelIntegration}
                                    disabled={loadingSyncIntegrationShippingMethods || isLoadingChannelIntegration}
                                >
                                    {t("integrations_list.active.Sync_shipping_methods")}
                                </ButtonLoader>
                            )}
                            {channelType.name == 'bling' && (
                            <>
                                <ButtonLoader
                                    className="whitespace-nowrap mr-3"
                                    type={"secondary"}
                                    onClick={() => setShowCreateIntegrationShippingMethodModal(true)}
                                >
                                    {t("integrations_list.shipping.Add_Shipping_Method")}
                                </ButtonLoader>
                            </>
                            )}
                            <ButtonLoader onClick={() => history.push(storePath + INTEGRATIONS)}>
                                {t("integrations_list.shipping.return_to_integrations")}
                            </ButtonLoader>
                        </div>
                    </PageTopBar>
                }
            >
                <div className="relative w-full min-h-10">
                    <Loader show={isLoadingChannelIntegration || isFetchingChannelIntegration} />
                    <Table
                        dataSource={tableData}
                        columns={integrationShippingMethodsColumns}
                        pagination={false}
                        rowKey={(row) => row.id}
                        locale={{
                            emptyText: (
                                <Empty
                                    description={
                                        <span>
                                            {t("integrations_list.shipping.empty_shipping_methods")},{" "}
                                            {allowNewIntegrationShippingMethods ? (
                                                <span className="cursor-pointer underline" onClick={() => setShowCreateIntegrationShippingMethodModal(true)}>
                                                    {t("integrations_list.shipping.empty_shipping_methods_create")}
                                                </span>
                                            ) : (
                                                <span className="cursor-pointer underline" onClick={() => syncIntegrationShippingMethods()}>
                                                    {t("integrations_list.shipping.empty_shipping_methods_sync")}
                                                </span>
                                            )}
                                        </span>
                                    }
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            ),
                        }}
                    />
                </div>
            </PageView>
        </>
    )
}
