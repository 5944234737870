import { useContext } from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from "react-i18next"
import { InformationCircleIcon } from '@heroicons/react/outline'
import moment from "moment-timezone"

import OrderEta from "./OrderEta"
import { UserContext } from '../hooks/UserContext'

const OrderEtaContainer = ({order, className}) => {
    const { i18n } = useTranslation()
    const { user } = useContext(UserContext)

    const country = user.current_store?.warehouses[0]?.country
    const timeZone = user.current_store?.warehouses[0]?.time_zone
    const userInSameTimezone = timeZone === Intl.DateTimeFormat().resolvedOptions().timeZone ? true : false
    const tooltipTimeZone = country === "MX" ? "CDMX" : country === "BR" ? "SP" : timeZone

    const firstEta = order?.first_estimated_time_arrival_range
    const lastEta = order?.latest_estimated_time_arrival_range

    const formattedFirstEtaRange = firstEta ? `${moment(firstEta.range_begin).tz(timeZone).format('L')}-${moment(firstEta.range_end).tz(timeZone).format('L')}` : ""
    const formattedLastEtaRange = lastEta ? `${moment(lastEta.range_begin).tz(timeZone).format('L')}-${moment(lastEta.range_end).tz(timeZone).format('L')}` : ""

    const isEtaRescheduled = firstEta && lastEta && formattedFirstEtaRange !== formattedLastEtaRange ? true : false
    const isFirstEtaDelayed = moment(firstEta?.range_end).tz(timeZone).endOf('day') < moment().tz(timeZone).endOf('day') ? true : false
    const isLastEtaDelayed = moment(lastEta?.range_end).tz(timeZone).endOf('day') < moment().tz(timeZone).endOf('day') ? true : false

    return (
        <div className={`flex flex-col text-gray-700 ${className ? className : ""}`}>
            {!!lastEta && (
                <div className='flex items-center'>
                    <span className={`${isLastEtaDelayed ? 'text-red-400' : ''}`}>
                        <span>{`${isEtaRescheduled ? i18n.t("orders.list.eta_rescheduled") : i18n.t("orders.list.eta")}:`}</span>
                        <span>&nbsp;</span>
                        <OrderEta orderEta={lastEta} timeZone={timeZone} />
                    </span>

                    {!userInSameTimezone && (
                        <Tooltip placement="bottom" title={i18n.t("orders.list.time_zone_info", {time_zone: tooltipTimeZone})}>
                            <InformationCircleIcon className="inline w-4 h-4 ml-1 cursor-help" />
                        </Tooltip>
                    )}
                </div>
            )}

            {isEtaRescheduled && (
                <div className='flex items-center'>
                    <span className={`${isFirstEtaDelayed ? 'text-red-400' : 'text-gray-700'}`}>
                        <span>{`${i18n.t("orders.list.original_eta")}:`}</span>
                        <span>&nbsp;</span>
                        <OrderEta orderEta={firstEta} timeZone={timeZone} />
                    </span>

                    {!userInSameTimezone && (
                        <Tooltip placement="bottom" title={i18n.t("orders.list.time_zone_info", {time_zone: tooltipTimeZone})}>
                            <InformationCircleIcon className="inline w-4 h-4 ml-1 cursor-help" />
                        </Tooltip>
                    )}
                </div>
            )}
        </div>
    )
}

export default OrderEtaContainer