import { useQuery } from "react-query"
import { fetchOrder } from "../../../services"
import { useParams } from "react-router-dom"
import { Button, ConfirmDialog, SwitchPanel, TextareaField } from "../../../components"
import { Loader } from "../../../components/Loader"
import { useContext, useEffect, useMemo, useState } from "react"
import { InputNumber, Radio, Select } from "antd"
import { DamagedIcon } from "../../../components/Icons/DamagedIcon"
import { TrashIcon } from "../../../components/Icons/TrashIcon"
import { SelectReturnedProductDialog } from "./SelectReturnedProductDialog"
import { createPlannedReturn } from "../../../services/returnServices"
import {
  getStatusDefinition,
  ORDER_STATUSES,
} from "../../../components/BasicTable/StatusPillDefinitions"
import { UserContext } from "../../../hooks/UserContext"
import  i18n from "../../../translations/index";
import { Switch } from "@headlessui/react"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const getReturnTypeDefinition = (type = "") => {
  let foundIndex = RETURN_TYPES.findIndex(def => def.value === type)
  if (foundIndex === -1) {
    return null
  }
  else {
    return RETURN_TYPES[foundIndex]
  }
}

const RETURN_TYPES = [
  {
    value: "SIMPLE_RETURN",
    label: i18n.t("return.container.return"),
    help: i18n.t("return.container.awaiting_return"),
  },
  {
    value: "EXCHANGE",
    label: i18n.t("return.container.change"),
    help: i18n.t("return.container.replacement_products"),
  },
  {
    value: "IMMEDIATE_EXCHANGE",
    label: i18n.t("return.container.immediate_change"),
    help: i18n.t("return.container.immediate_change_help"),
    disabled: false,
  },
]
const optionsWithDisabled = [
  { label: i18n.t("return.container.yes"), value: true },
  { label: i18n.t("return.container.no"), value: false },
]

export const CreateReturnContainer = ({
  onSuccess = () => {},
  onCancel = () => {},
}) => {
  const { order_id } = useParams()
  const { user } = useContext(UserContext)
  
  const [invalidOrder, setInvalidOrder] = useState(false)
  const [modifiedReturnData, setModifiedReturnData] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [showSelectReturnedProductDialog, setShowSelectReturnedProductDialog] =
    useState(false)
  const [showSelectExchangeProductDialog, setShowSelectExchangeProductDialog] =
    useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [ignoreNextModifiedReturnData, setIgnoreNextModifiedReturnData] =
    useState(true)
  const [returnData, setReturnData] = useState({
    order_id: null,
    notes: "",
    return_type: null,
    products: [],
    generate_guide: null,
    exchange_products: [],
    pickup_at_home: false
  })

  const {
    isLoading,
    isFetching,
    isError,
    error,
    data: orderData,
  } = useQuery(["order", order_id], () => fetchOrder(order_id))

  useEffect(() => {
    // console.log("orderData", orderData)
    if (orderData === undefined) {
      return
    }
    if (orderData.status != "shipped") {
      setInvalidOrder(true)
    } else {
      setInvalidOrder(false)
      // console.log("returnData", returnData)
      if (!modifiedReturnData || returnData.order_id != orderData.id) {
        let orderProducts = []
        orderData.order_lines.forEach((ol) => {
          if (!ol.product) { return true}
          if (ol.product.is_kit) {
            ol.product.kit_childs.forEach((kc) => {
              orderProducts.push({
                product_id: kc.child_product.id,
                quantity: kc.quantity * ol.quantity,
                label: kc.child_product.name,
                sku:
                  kc.child_product.product_codes.length > 0
                    ? kc.child_product.product_codes[0].sku
                    : null,
                upc: kc.child_product.bar_code,
                damaged: false,
              })
            })
          } else {
            orderProducts.push({
              product_id: ol.product.id,
              quantity: ol.quantity,
              label: ol.product.name,
              sku:
                ol.product.product_codes.length > 0
                  ? ol.product.product_codes[0].sku
                  : null,
              upc: ol.product.bar_code,
              damaged: false,
            })
          }
        })
        setIgnoreNextModifiedReturnData(true)
        setReturnData({
          ...returnData,
          order_id: orderData.id,
          products: orderProducts,
        })
      }
    }
  }, [orderData])

  useEffect(() => {
    if (!modifiedReturnData && !ignoreNextModifiedReturnData) {
      // console.log("modified return data")
      setModifiedReturnData(true)
    }
    setIgnoreNextModifiedReturnData(false)
  }, [returnData])

  const handleQuantityChange = (value, productIndex) => {
    // console.log("value:", value)
    // console.log("index:", productIndex)
    let products = returnData.products
    products[productIndex].quantity = value
    setReturnData({ ...returnData, products: products })
  }

  const handleReplacementQuantityChange = (value, productIndex) => {
    // console.log("value:", value)
    // console.log("index:", productIndex)
    let products = returnData.exchange_products
    products[productIndex].quantity = value
    setReturnData({ ...returnData, exchange_products: products })
  }

  const addDamagedProduct = (productIndex) => {
    let damagedProduct = {
      ...returnData.products[productIndex],
      damaged: true,
      quantity: 1,
    }
    let products = [...returnData.products]
    if (products[productIndex].quantity > 0) {
      products[productIndex].quantity -= 1
    }
    let foundIndex = returnData.products.findIndex(
      (p) => p.damaged && p.product_id === damagedProduct.product_id
    )
    if (foundIndex === -1) {
      products.push(damagedProduct)
    } else {
      products[foundIndex].quantity += 1
    }
    setReturnData({ ...returnData, products: [...products] })
  }

  const removeReplacementProduct = (productIndex) => {
    let products = [...returnData.exchange_products]
    products.splice(productIndex, 1)
    setReturnData({ ...returnData, exchange_products: [...products] })
  }

  const removeProduct = (productIndex) => {
    let products = [...returnData.products]
    products.splice(productIndex, 1)
    setReturnData({ ...returnData, products: [...products] })
  }

  const addProduct = (productDataArray) => {
    // console.log(productDataArray)
    let products = [...returnData.products]
    productDataArray.forEach((productData) => {
      let productsToAdd = []
      if (productData.is_kit && productData.kit_childs) {
        productData.kit_childs.forEach((kc) => {
          for (let index = 0; index < kc.quantity; index++) {
            productsToAdd.push({ ...kc.child_product })
          }
        })
      } else {
        productsToAdd.push({ ...productData })
      }
      // console.log("productsToAdd", productsToAdd)
      productsToAdd.forEach((productToAdd) => {
        let foundIndex = products.findIndex(
          (p) => !p.damaged && p.product_id === productToAdd.id
        )
        if (foundIndex === -1) {
          products.push({
            product_id: productToAdd.id,
            quantity: 1,
            label: productToAdd.name,
            sku:
              productToAdd.product_codes.length > 0
                ? productToAdd.product_codes[0].sku
                : null,
            upc: productToAdd.bar_code,
            damaged: false,
          })
        } else {
          products[foundIndex].quantity += 1
        }
      })
    })
    setReturnData({ ...returnData, products: [...products] })
    setShowSelectReturnedProductDialog(false)
  }
  const addExchangeProduct = (productDataArray) => {
    // console.log(productDataArray)
    let products = [...returnData.exchange_products]
    productDataArray.forEach((productData) => {
      let productsToAdd = []
      productsToAdd.push({ ...productData })
      // console.log("productsToAdd", productsToAdd)
      productsToAdd.forEach((productToAdd) => {
        let foundIndex = products.findIndex(
          (p) => !p.damaged && p.product_id === productToAdd.id
        )
        if (foundIndex === -1) {
          products.push({
            product_id: productToAdd.id,
            quantity: 1,
            label: productToAdd.name,
            sku:
              productToAdd.product_codes.length > 0
                ? productToAdd.product_codes[0].sku
                : null,
            upc: productToAdd.bar_code,
            damaged: false,
          })
        } else {
          products[foundIndex].quantity += 1
        }
      })
    })
    setReturnData({ ...returnData, exchange_products: [...products] })
    setShowSelectExchangeProductDialog(false)
  }

  const handleNotesChange = (val) => {
    setReturnData({ ...returnData, notes: val })
  }

  const handleReturnTypeChange = (val) => {
    // console.log("return type:", val)
    setReturnData({ ...returnData, return_type: val })
  }
  const handleGenerateGuideChange = (val) => {
    // console.log("return type:", val)
    setReturnData({ ...returnData, generate_guide: val })
  }

  const handlePickupAtHomeChange = (val) => {
    // console.log("return type:", val)
    setReturnData({ ...returnData, pickup_at_home: val })
  }

  const totalProductItems = useMemo(() => {
    let count = 0
    returnData.products.forEach((p) => {
      count += p.quantity
    })
    return count
  }, [returnData])

  const onSubmitConfirm = async () => {
    setLoadingSubmit(true)
    try {
      // console.log(returnData)
      // console.log(JSON.stringify(returnData))
      let res = await createPlannedReturn(order_id, returnData)
      setModifiedReturnData(false)
      setIgnoreNextModifiedReturnData(true)
      setShowConfirmDialog(false)
      setLoadingSubmit(false)
      onSuccess(res.id ? res.id : null)
    } catch (error) {
      console.log(error.response?.data)
      setErrorMessage(error.response?.data.error)
      setLoadingSubmit(false)
    }
  }

  const formValid = useMemo(() => {
    if (
      (returnData.return_type === "EXCHANGE" ||
        returnData.return_type === "IMMEDIATE_EXCHANGE") &&
      returnData.exchange_products.length === 0
    ) {
      return false
    }
    if (
      returnData.return_type === null ||
      returnData.generate_guide === null ||
      returnData.products.length === 0
    ) {
      return false
    }
    return true
  }, [returnData])

  return (
    <>
      {isLoading || isFetching ? (
        <>
          <Loader show={true}></Loader>
        </>
      ) : isError ? (
        <>Error: {error.message}</>
      ) : invalidOrder ? (
        <>
          {i18n.t("return.container.not_readable")} (
          {getStatusDefinition(orderData.status, ORDER_STATUSES).label})
        </>
      ) : 
      // user.current_store?.warehouses[0]?.country === 'CO' ? (
      //   <div>
      //     Próximamente para Colombia
      //   </div>
      // ) : 
      (
        <div>
          <div className="">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {i18n.t("return.container.order_number")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  #{orderData.order_number}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{i18n.t("return.container.store")}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {orderData.store.name}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {i18n.t("return.container.return_type")}<span className="text-red-500">*</span>
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Radio.Group
                    options={RETURN_TYPES}
                    onChange={(e) => handleReturnTypeChange(e.target.value)}
                    value={returnData.return_type}
                    optionType="button"
                    buttonStyle="solid"
                  />
                  <div className="text-gray-600 pt-2">
                    <i>{getReturnTypeDefinition(returnData.return_type)?.help}</i>
                  </div>
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {i18n.t("return.container.return_guide")}{" "}
                  <span className="text-red-500">*</span>
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Radio.Group
                    options={optionsWithDisabled}
                    onChange={(e) => handleGenerateGuideChange(e.target.value)}
                    value={returnData.generate_guide}
                    optionType="button"
                    buttonStyle="solid"
                  />
                  {returnData.generate_guide === true && (
                    <div className="text-gray-600 pt-2">
                      <i>{i18n.t("return.container.generate_guide")}
                        
                      {user.current_store?.warehouses[0]?.country === 'CO' ? (
                         <b> Servientrega.</b> 
                      ): user.current_store?.warehouses[0]?.country === 'BR' ? (
                        <b> Correios.</b> 
                      ):(
                        <b> DHL.</b> 
                      )}
                        </i>
                    </div>
                  )}
                  {returnData.generate_guide === false && (
                    <div className="text-gray-600 pt-2">
                      <i>
                        {i18n.t("return.container.return_instructions")}
                      </i>
                    </div>
                  )}
                </dd>
                {user.current_store?.warehouses[0]?.country === 'BR' ? (
                  <>
                  <dt className="text-sm font-medium text-gray-500">
                    <div className="mt-2">
                        <span className="text-sm font-medium text-gray-500">{i18n.t("return.container.pickup_at_home_title")}</span>
                    </div>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Switch
                        checked={returnData.pickup_at_home}
                        onChange={(value) => handlePickupAtHomeChange(value)}
                        className={classNames(
                          returnData.pickup_at_home ? 'bg-indigo-600' : 'bg-gray-200',
                            'mt-2 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        )}
                    >
                        <span className="sr-only">Use setting</span>
                        <span
                            aria-hidden="true"
                            className={classNames(
                              returnData.pickup_at_home ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                        />
                    </Switch>
                    {returnData.pickup_at_home && <div className="text-gray-600 pt-2">
                      <i>
                        {i18n.t("return.container.pickup_at_home_description")}
                      </i>
                    </div>}
                  </dd>
                </>) : <></>}
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{i18n.t("return.container.notes")}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <TextareaField onChange={(val) => handleNotesChange(val)} />
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {i18n.t("return.container.returnees")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Button
                    type="secondary"
                    className="float-right"
                    onClick={() => setShowSelectReturnedProductDialog(true)}
                  >
                    {i18n.t("return.container.add_another_product")} 
                  </Button>
                </dd>
              </div>
              <div className="bg-white px-6 pb-5 w-full">
                <table className="w-full text-left">
                  <thead>
                    <tr>
                      <th>{i18n.t("return.container.products")}</th>
                      <th>UPC</th>
                      <th>SKU</th>
                      <th>{i18n.t("return.container.quantity")}</th>
                      <th className="w-0"></th>
                      <th className="w-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {returnData.products.map((product, index) => (
                      <tr
                        className="text-gray-500"
                        key={
                          product.damaged
                            ? String(product.product_id) + index + "D"
                            : String(product.product_id) + index 
                        }
                      >
                        <td>
                          {product.label}{" "}
                          {product.damaged && (
                            <i className="text-red-500">{i18n.t("return.container.damaged_parts")}</i>
                          )}
                        </td>
                        <td>{product.upc ? product.upc : "Sin UPC"}</td>
                        <td>
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mx-1">
                            {product.sku}
                          </span>
                        </td>
                        <td>
                          <InputNumber
                            min={0}
                            // size="large"
                            value={product.quantity}
                            onChange={(value) =>
                              handleQuantityChange(value, index)
                            }
                            keyboard={true}
                          />
                        </td>
                        <td>
                          {!product.damaged && (
                            <Button
                              type="danger"
                              className="px-2 has-tooltip"
                              dense
                              onClick={() => addDamagedProduct(index)}
                            >
                              <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                                {i18n.t("return.container.add_part")}
                              </span>
                              <DamagedIcon className="text-white w-4 h-4" />
                            </Button>
                          )}
                        </td>
                        <td>
                          <Button
                            className="px-2 has-tooltip ml-2"
                            dense
                            type="secondary"
                            onClick={() => removeProduct(index)}
                          >
                            <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                              {i18n.t("return.container.remove_product")}
                            </span>
                            <TrashIcon className="w-4 h-4" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {returnData.return_type &&
                returnData.return_type != "SIMPLE_RETURN" && (
                  <div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {i18n.t("return.container.replacement")}<span className="text-red-500">*</span>
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <Button
                          type="secondary"
                          className="float-right"
                          onClick={() =>
                            setShowSelectExchangeProductDialog(true)
                          }
                        >
                          {i18n.t("return.container.add_another_product")} 
                        </Button>
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-6 pb-5 w-full">
                      <table className="w-full text-left">
                        <thead>
                          <tr>
                            <th>{i18n.t("return.container.products")}</th>
                            <th>UPC</th>
                            <th>SKU</th>
                            <th>{i18n.t("return.container.quantity")}</th>
                            <th className="w-0"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {returnData.exchange_products.map(
                            (product, index) => (
                              <tr
                                className="text-gray-500"
                                key={
                                  product.damaged
                                    ? String(product.product_id) + "D"
                                    : String(product.product_id)
                                }
                              >
                                <td>{product.label} </td>
                                <td>{product.upc ? product.upc : "Sin UPC"}</td>
                                <td>
                                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mx-1">
                                    {product.sku}
                                  </span>
                                </td>
                                <td>
                                  <InputNumber
                                    min={0}
                                    // size="large"
                                    value={product.quantity}
                                    onChange={(value) =>
                                      handleReplacementQuantityChange(value, index)
                                    }
                                    keyboard={true}
                                  />
                                </td>

                                <td>
                                  <Button
                                    className="px-2 has-tooltip ml-2"
                                    dense
                                    type="secondary"
                                    onClick={() => removeReplacementProduct(index)}
                                  >
                                    <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                                      {i18n.t("return.container.remove_product")}
                                    </span>
                                    <TrashIcon className="w-4 h-4" />
                                  </Button>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
            </dl>
          </div>
          <div className="flex place-content-end">
            <Button
              disabled={!formValid}
              className="mr-2"
              onClick={() => onCancel()}
              type="secondary"
            >
              {i18n.t("return.container.cancel")}
            </Button>
            <Button
              disabled={!formValid}
              className=""
              onClick={() => setShowConfirmDialog(true)}
            >
              {i18n.t("return.container.save")}
            </Button>
          </div>
          <ConfirmDialog
            open={showConfirmDialog}
            setOpen={(val) => setShowConfirmDialog(val)}
            cancelLabel= {i18n.t("return.container.cancel")}
            confirmLabel= {i18n.t("return.container.confirm")}
            description={` ${i18n.t("return.container.total_return")} ${totalProductItems} ${i18n.t("return.container.product")}${
              totalProductItems > 1 ? "s" : ""
            }.`}
            title= {i18n.t("return.container.cancel")} 
            loading={loadingSubmit}
            errorMessage={errorMessage}
            onConfirm={() => onSubmitConfirm()}
          />
          <SelectReturnedProductDialog
            open={showSelectReturnedProductDialog}
            onCancel={() => setShowSelectReturnedProductDialog(false)}
            storeData={orderData?.store}
            onConfirm={(productDataArray) => addProduct(productDataArray)}
          />
          <SelectReturnedProductDialog
            open={showSelectExchangeProductDialog}
            onCancel={() => setShowSelectExchangeProductDialog(false)}
            storeData={orderData?.store}
            onConfirm={(productDataArray) =>
              addExchangeProduct(productDataArray)
            }
          />
        </div>
      )}
    </>
  )
}
