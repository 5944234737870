import {
    AcademicCapIcon,
    BadgeCheckIcon,
    CashIcon,
    ClockIcon,
    ReceiptRefundIcon,
    UsersIcon,
} from '@heroicons/react/outline'
import {useRouteMatch, useHistory, Link} from "react-router-dom";
import shopify from '../../assets/shopify.png';
import magento from '../../assets/magento.png';
import vtex from '../../assets/vtex.jpg';
import ventiapp from '../../assets/ventiapp.png';
import walmart from '../../assets/walmart.png';
import meli_logo from '../../assets/mercadolibre.jpg';
import liverpool_logo from '../../assets/liverpool.jpeg';
import coppel_logo from '../../assets/coppel.webp';
import tiendanube from '../../assets/tiendanube.png';
import f1commerce_logo from '../../assets/f1commerce.png';
import t1paginas_logo from '../../assets/t1paginas.png';
import bling from '../../assets/bling.jpeg';
import amazon from '../../assets/amazon.png';
import mercadolibre from '../../assets/mercadolibre.jpg';
import stripe_logo from '../../assets/stripe.png';
import shopee_logo from '../../assets/shopee.png';
import shein_logo from '../../assets/shein.png';
import tray_logo from '../../assets/traycommerce.png';
import tiny_logo from '../../assets/tiny.jpeg';
import woocommerce from '../../assets/woocommerce.png';
import traycorp from '../../assets/traycorp.svg';
import vnda_logo from '../../assets/vnda.jpg';
import {ADD_MELI_INTEGRATION, ADD_LIVERPOOL_INTEGRATION, ADD_COPPEL_INTEGRATION, ADD_SHOPIFY_INTEGRATION, ADD_SHOPIFY_PUBLIC_INTEGRATION, ADD_VTEX_INTEGRATION,ADD_VENTIAPP_INTEGRATION, ADD_TIENDANUBE_INTEGRATION, ADD_WOOCOMMERCE_INTEGRATION, ADD_WALMART_INTEGRATION, ADD_BLING_INTEGRATION, ADD_AMAZON_INTEGRATION, ADD_F1COMMERCE_INTEGRATION, ADD_TRAYCORP_INTEGRATION, ADD_VNDA_INTEGRATION, ADD_T1PAGINAS_INTEGRATION, ADD_TRAY_INTEGRATION, ADD_STRIPE_INTEGRATION, ADD_SHEIN_INTEGRATION, ADD_SHOPEE_INTEGRATION, ADD_TINY_INTEGRATION} from "../../navigation";
import {useMemo, useContext} from "react";
import {UserContext} from "../../hooks/UserContext";
import { STORE_PATH } from '../../navigation/constants';
import { useTranslation } from 'react-i18next';



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function ShowAvailableIntegrations(){

    const { t, i18n } = useTranslation(); 
    const {user} = useContext(UserContext)
    const { url:storePath } = useRouteMatch({ path: STORE_PATH })


    const ACTIONS = [
        // {
        //     title: 'Shopify',
        //     href: ADD_SHOPIFY_INTEGRATION,
        //     image: shopify,
        //     icon: ClockIcon,
        //     iconForeground: 'text-teal-700',
        //     iconBackground: 'bg-teal-50',
        // },
        {
            title: 'Woocommerce',
            href: storePath + ADD_WOOCOMMERCE_INTEGRATION,
            image: woocommerce,
            icon: BadgeCheckIcon,
            iconForeground: 'text-grey-700',
            iconBackground: 'bg-purple-50'
        },
        // {
        //     title: 'Magento',
        //     href: storePath + ADD_SHOPIFY_INTEGRATION,
        //     image: magento,
        //     icon: UsersIcon,
        //     iconForeground: 'text-light-blue-700',
        //     iconBackground: 'bg-light-blue-50',
        // },
        {
            title: 'Vtex',
            image: vtex,
            href: storePath + ADD_VTEX_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-pink-50'
        },

        {
            title: 'Ventiapp',
            image: ventiapp,
            href: storePath + ADD_VENTIAPP_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-green-50'
        },
        {
            title: 'Walmart',
            image: walmart,
            href: storePath + ADD_WALMART_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-blue-50'
        },
        {
            title: 'Mercado Libre',
            image: meli_logo,
            href: storePath + ADD_MELI_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-yellow-50'
        },
        // {
        //     title: 'Liverpool',
        //     image: liverpool_logo,
        //     href: storePath + ADD_LIVERPOOL_INTEGRATION,
        //     icon: CashIcon,
        //     iconForeground: 'text-pink-700',
        //     iconBackground: 'bg-pink-50'
        // },
        {
            title: 'Coppel',
            image: coppel_logo,
            href: storePath + ADD_COPPEL_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-yellow-50'
        },
        {
            title: 'Tienda Nube',
            image: tiendanube,
            href: storePath + ADD_TIENDANUBE_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-blue-50'
        },
        {
            title: 'Bling',
            image: bling,
            href: storePath + ADD_BLING_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-green-50'
        },
        {
            title: 'Tray Corp',
            image: traycorp,
            href: storePath + ADD_TRAYCORP_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-green-50'
        },
        {
            title: 'F1commerce',
            image: f1commerce_logo,
            href: storePath + ADD_F1COMMERCE_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-red-50'
        },
        {
            title: 'T1Páginas',
            image: t1paginas_logo,
            href: storePath + ADD_T1PAGINAS_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-red-700',
            iconBackground: 'bg-red-50'
        },
        {
            title: 'Vnda',
            image: vnda_logo,
            href: storePath + ADD_VNDA_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-orange-700',
            iconBackground: 'bg-orange-50'
        },
        {
            title: 'Stripe',
            image: stripe_logo,
            href: storePath + ADD_STRIPE_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-purple-700',
            iconBackground: 'bg-purple-50'
        },
        {
            title: 'Tray Commerce',
            image: tray_logo,
            href: storePath + ADD_TRAY_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-blue-700',
            iconBackground: 'bg-blue-50'
        },
        {
            title: 'Tiny Olist',
            image: tiny_logo,
            href: storePath + ADD_TINY_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-blue-700',
            iconBackground: 'bg-blue-50'
        },
        {
            title: 'Shopee',
            image: shopee_logo,
            href: storePath + ADD_SHOPEE_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-orange-700',
            iconBackground: 'bg-orange-50'
        },
        {
            title: 'Shein',
            image: shein_logo,
            href: storePath + ADD_SHEIN_INTEGRATION,
            icon: CashIcon,
            iconForeground: 'text-gray-700',
            iconBackground: 'bg-gray-50'
        }
        // {
        //     title: 'Amazon',
        //     href: storePath + ADD_AMAZON_INTEGRATION,
        //     image: amazon,
        //     icon: ReceiptRefundIcon,
        //     iconForeground: 'text-rose-700',
        //     iconBackground: 'bg-rose-50'
        // },
        //{
        //     title: 'Mercado-Libre (Pronto)',
        //     href: ADD_SHOPIFY_INTEGRATION,
        //     image: mercadolibre,
        //     icon: AcademicCapIcon,
        //     iconForeground: 'text-indigo-700',
        //     iconBackground: 'bg-indigo-50',
        //     comingSoon: true
        // },
    ]

    return (
        <div className="relative bg-white py-16 sm:py-24 lg:py-10">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">{i18n.t("integrations.show_available.Integrations")}</h2>
                <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                    {i18n.t("integrations.show_available.import_ecommerce")}
                </p>
                <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                    {i18n.t("integrations.show_available.select_channel")}
                </p>
                <div className="mt-12">
                        

                        <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px bg-white">
                                
                            {
                                ["ignasivegas1@gmail.com", "ignasi2@cubbo.com", "ignasi+shopify@cubbo.com"].includes(user.email) ? 
                                
                                
                                <>
                                
                                    <div
                                        key="Shopify"
                                        className={classNames(
                                            // actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                                            // actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                                            // actionIdx === ACTIONS.length - 2 ? 'sm:rounded-bl-lg' : '',
                                            // actionIdx === ACTIONS.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                                            'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                                        )}
                                    >
                                        <div>
                                            <span
                                                className={classNames(
                                                    'bg-teal-50',
                                                    'text-teal-700',
                                                    'rounded-lg inline-flex p-3 ring-4 ring-white'
                                                )}
                                            >
                                            <img src={shopify} className="h-10 w-10"/>
                                            </span>
                                        </div>
                                        <div className="mt-8">
                                            <h3 className="text-lg font-medium">
                                               
                                                    <Link to={storePath + ADD_SHOPIFY_PUBLIC_INTEGRATION} className="focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                        Shopify
                                                    </Link>    
                                                
                                            </h3>
                                        </div>
                                        <span
                                            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                                            aria-hidden="true"
                                        >
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                        </svg>
                                      </span>
                                    </div>

                                
                                </> 
                                
                                
                                : 
                                
                                <>

                                    <div
                                        key="Shopify Private"
                                        className={classNames(
                                            // actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                                            // actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                                            // actionIdx === ACTIONS.length - 2 ? 'sm:rounded-bl-lg' : '',
                                            // actionIdx === ACTIONS.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                                            'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                                        )}
                                    >
                                        <div>
                                            <span
                                                className={classNames(
                                                    'bg-green-50',
                                                    'text-teal-700',
                                                    'rounded-lg inline-flex p-3 ring-4 ring-white'
                                                )}
                                            >
                                            <img src={shopify} className="h-10 w-10"/>
                                            </span>
                                        </div>
                                        <div className="mt-8">
                                            <h3 className="text-lg font-medium">
                                               
                                                    <Link to={storePath + ADD_SHOPIFY_INTEGRATION} className="focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                        Shopify Private
                                                    </Link>    
                                                
                                            </h3>
                                        </div>
                                        <span
                                            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                                            aria-hidden="true"
                                        >
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                        </svg>
                                      </span>
                                </div>

                                </>
                            }
                                
                                

                                

                                

                                

                            
                            {ACTIONS.map((action, actionIdx) => (
                                <div
                                    key={action.title}
                                    className={classNames(
                                        actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                                        actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                                        actionIdx === ACTIONS.length - 2 ? 'sm:rounded-bl-lg' : '',
                                        actionIdx === ACTIONS.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                                        'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                                    )}
                                >
                                    <div>
                                        <span
                                            className={classNames(
                                                action.iconBackground,
                                                action.iconForeground,
                                                'rounded-lg inline-flex p-3 ring-4 ring-white'
                                            )}
                                        >
                                          <img src={action.image} className="h-10 w-10"/>
                                        </span>
                                        </div>
                                        <div className="mt-8">
                                            <h3 className="text-lg font-medium">
                                                {action.comingSoon ?
                                                action.title
                                                :
                                                    <Link to={action.href} className="focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                        {action.title}
                                                    </Link>    
                                                }
                                            </h3>
                                        </div>
                                        <span
                                            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                                            aria-hidden="true"
                                        >
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                        </svg>
                                      </span>
                                </div>
                            ))}
                        </div>



                </div>
            </div>
        </div>
    )
}




